import axios from 'axios';
import jwtdecode from 'jwt-decode';
import { JwtPayload } from 'jwt-decode';

export let jwt = "";  

export const setJET = (jwtNew : string ) => {
    jwt = jwtNew;
}

export const getDecodedAccessToken = (token: string) => {
  try {
    return jwtdecode(token) as JwtPayload;
  } catch (Error) {
    return null;
  }
};


const axiosInstance = axios.create({  
    // headers: {  
    //   'Cache-Control': 'no-cache',  
    //   'Content-Type': 'application/json',  
    //   // Pragma: 'no-cache',  
    //   // Expires: '0',  
    // },  
    
    baseURL: `${process.env.REACT_APP_API_URL}`,  
  });  

const authorizedInstance = () => {
  axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + jwt;

  return axiosInstance;
};

export default authorizedInstance;