/* eslint-disable react/react-in-jsx-scope */
/**
 * Copyright 2023 Amazon.com, Inc. and its affiliates. All Rights Reserved.
 *
 * Licensed under the Amazon Software License (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 *   http://aws.amazon.com/asl/
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

import "./App.css";

import { useState } from "react";
import { User, useAuth0 } from '@auth0/auth0-react';

// import { withAuthenticator } from "@aws-amplify/ui-react";
// import type { WithAuthenticatorProps } from '@aws-amplify/ui-react';
import { Box } from "@mui/system";
import {
    Typography,
    Modal,
    Button
} from "@mui/material";
import Header from "./components/Header";
import Chat from "./containers/Chat";
import { UserSessionHistory } from "./containers/UserSessionHistory";
import { ChatService, ServiceResponse, UserSession } from "./containers/ChatService";
import { useEffect } from "react";
import authorizedInstance from "./containers/axiosService";

// import "@aws-amplify/ui-react/styles.css";
import { getDecodedAccessToken, jwt, setJET } from "./containers/axiosService";

export function App() {
    const {
        isLoading,
        isAuthenticated,
        error,
        user,
        loginWithRedirect,
        getAccessTokenSilently,
        // handleRedirectCallback,
    } = useAuth0();

    const chatService: ChatService = new ChatService();

    const [showUserSessionHistory, setShowUserSessionHistory] = useState(false);
    const [sessionId, setSessionId] = useState<string | null>(null);
    const [historyRerenderCounter, setHistoryRerenderCounter] = useState<number>(0);
    const [creatingNewSession, setCreatingNewSession] = useState<boolean>(false);
    const [tokenDelivered, setTokenDelivered] = useState<boolean>(false);

    async function createNewUserSession() {
        setCreatingNewSession(true);
        const response = await chatService.newChatSession(user!.nickname!);
        if (response.status === "success" && response.payload !== undefined) {
            localStorage.setItem("sessionId", response.payload!.sessionId);
            localStorage.setItem("newSessionId", response.payload!.sessionId);
            setSessionId(response.payload!.sessionId);
            setHistoryRerenderCounter(historyRerenderCounter + 1);
        }
        setCreatingNewSession(false);
    }

    useEffect(() => {
        if (!isLoading) {
            if (
                jwt === "" ||
                (jwt !== "" && getDecodedAccessToken(jwt)
                    ? getDecodedAccessToken(jwt)!.exp! < new Date().getTime() / 1000
                    : true)
            ) {
                const fn = async () => {
                    const token = await getAccessTokenSilently();
                    setJET(token);
                    setTokenDelivered(true)
                };
                fn();
            }
        }
    });

    useEffect(() => {
        if (!error && !isAuthenticated && !isLoading) {
            const fn = async () => {
                await loginWithRedirect({
                    appState: { target: window.location.pathname, user: user },
                });
            };
            fn();
        }
    }, [
        error,
        isAuthenticated,
        isLoading,
        user,
        loginWithRedirect,
    ]);

    const isSessionHistoryEmpty = async (user: any, sessionId: string) => {
        try {
            const queryStringParameters = {
                user: user,
                sessionId: sessionId,
            };
            const response = await authorizedInstance().get("/publish-api/chat/history", { params: queryStringParameters });
            if (response.data.messages.length !== 0) {
                createNewUserSession();
            }
            else {
                setSessionId(sessionId);
                localStorage.setItem("sessionId", sessionId);
                if (!localStorage.getItem("newSessionId")) {
                    localStorage.setItem("newSessionId", sessionId);
                    setHistoryRerenderCounter(historyRerenderCounter + 1);
                }
            }
        } catch (error) {
            console.log(error);
            setSessionId(sessionId);
        }
    }

    // window.addEventListener("load", function () {
    //     setTimeout(function () {
    //         // This hides the address bar:
    //         window.scrollTo(0, 1);
    //     }, 0);
    // });

    useEffect(() => {
        if (isAuthenticated && tokenDelivered) {
            const interval = setInterval(async () => {
                const token = await getAccessTokenSilently();
                setJET(token);
            }, 1000 * 60 * 10);
            return () => clearInterval(interval);
        }
    }, [isAuthenticated, tokenDelivered]);

    useEffect(() => {
        if (user && tokenDelivered) {
            const savedSessionId = localStorage.getItem("newSessionId");
            if (savedSessionId) {
                isSessionHistoryEmpty(user.nickname!, savedSessionId);
            }
            else {
                createNewUserSession();
            }
        }
    }, [tokenDelivered])

    function signUserOut() {
        localStorage.removeItem("sessionId");
        setShowUserSessionHistory(false);
        setSessionId(null);
    }

    // Called from Header to display the Session History component
    function displayUserSessionHistory() {
        setShowUserSessionHistory(true);
    }

    // Called from Header when a new session is requested
    function handleNewSessionRequestedforUser() {
        if (user && tokenDelivered) {
            // When user request new session first it tries to reload new session if that is empty
            // otherwise creates a new one blank
            const savedSessionId = localStorage.getItem("newSessionId");
            if (savedSessionId) {
                isSessionHistoryEmpty(user.nickname!, savedSessionId);
            }
            else {
                createNewUserSession();
            }
        }
    }

    // Called from UserSessionHistory when a specific session is selected
    function handleOnSessionSelected(sessionId: string) {
        localStorage.setItem("sessionId", sessionId);
        setSessionId(sessionId);
        setShowUserSessionHistory(false);
    }

    // Called from UserSessionHistory when user closed the drawer
    function HandleOnCloseSessionHistoryRequested() {
        setShowUserSessionHistory(false);
    }

    if (error) {
        const fn = async () => {
            await loginWithRedirect({
                appState: { target: window.location.pathname, user: user },
            });
        };
        fn();
        // return <div>Access Denied</div>
    }

    return (
        !isLoading && isAuthenticated && tokenDelivered &&
        
        <Box sx={{ overflow: "hidden", height: "100%" }}>
            <Box
                p={0}
                m={0}
                display="flex"
                justifyContent="center"
            >
                <Header
                    user={user?.nickname || ""}
                    signOut={signUserOut}
                    displayUserSessionHistory={displayUserSessionHistory}
                    newSessionRequested={handleNewSessionRequestedforUser}
                />
            </Box>
            <Box
                // position='relative'
                height="90%"
            >
                {user && sessionId &&
                    <Chat
                        user={user.nickname || ""}
                        sessionId={sessionId}
                        creatingNewSession={creatingNewSession}
                    />
                }
                {user &&
                    <UserSessionHistory
                        user={user.nickname || ""}
                        showHistory={showUserSessionHistory}
                        historyRerenderCounter={historyRerenderCounter}
                        onSessionSelected={handleOnSessionSelected}
                        onCloseSessionHistoryRequested={HandleOnCloseSessionHistoryRequested}
                    />
                }
            </Box>
        </Box >
        ||
        <Box
            sx={{
                position: 'fixed',
                top: '30%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }}>
            <Box >
                <Box display='flex' justifyContent={'center'}>
                    <Box className="loader"></Box>
                    <Typography fontSize='16px' fontWeight='bold' mx='15px'>
                        Trying to authorize...
                    </Typography>
                </Box>
                <Typography fontSize='14px' fontWeight='bold' mt='10px'>
                    If this persists, please refresh the page or request access.
                </Typography>
            </Box>

        </Box>
    );
}

export default App;