/* eslint-disable react/no-deprecated */
import "./index.css";

import React from "react";
import { Auth0Provider, AppState, User, Auth0ProviderOptions } from '@auth0/auth0-react';

import ReactDOM from "react-dom";
import App from "./App";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { theme } from "./theme";
import { useHistory } from 'react-router-dom';

const AppStructure = () => {
    const history = useHistory();
        
    const onRedirectCallback = (appState?: AppState, user? : User) => {  
      console.log(appState, user)
      console.log({ flag: true, path: appState?.target });  
      // history.push("/");
    };

    return (
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN!}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
        redirectUri={`${window.location.origin}`}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE}
        onRedirectCallback={onRedirectCallback}
      >
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ThemeProvider>
      </Auth0Provider>
    );
  };

ReactDOM.render(
    <AppStructure />,
    document.getElementById("root"),
);

reportWebVitals();
