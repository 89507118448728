/**
 * Copyright 2023 Amazon.com, Inc. and its affiliates. All Rights Reserved.
 *
 * Licensed under the Amazon Software License (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 *   http://aws.amazon.com/asl/
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

import { createTheme } from "@material-ui/core/styles";

//@link https://cimdalli.github.io/mui-theme-generator/
//@todo set overrides for all core components,
// need to make sure imported components implement theming

const theme = createTheme({
    palette: {
        type: "light",
        primary: {
            main: "#ee0000",
        },
        secondary: {
            main: "#00b5e2",
        },
        background: {
            default: "rgba(255,255,255,0)",
        },
        divider: "rgba(255,255,255,.3)",
    },
    typography: {
        fontFamily: "Arial",
        htmlFontSize: 16,
        h1: {
            fontFamily: "Arial",
            fontWeight: 700,
        },
        h2: {
            fontFamily: "Arial",
            fontWeight: 700,
        },
        h3: {
            fontFamily: "Arial",
            fontWeight: 700,
        },
        h4: {
            fontFamily: "Arial",
            fontWeight: 700,
        },
        h5: {
            fontFamily: "Arial",
            fontWeight: 700,
        },
        h6: {
            fontFamily: "Arial",
            fontWeight: 700,
        },
    },
    shape: {
        borderRadius: 0,
    },
});

export { theme };
